<template>
    <div>
        <b-row class="mt-3">
            <b-col cols="12" class="d-flex justify-content-end align-item-center mb-5">
            </b-col>
            <b-col class="d-flex justify-content-center align-item-center mb-5">
                <h1 class="text-center">{{ $t('bahcesehir_university') }}</h1>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-center align-item-center">
            </b-col>
        </b-row>
        <CommonModal size="lg" ref="sendForm">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('initial_complaint_form') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <sendForm v-if="formName=='send-form'" @sendSuccessForm="successForm"></sendForm>
                </div>
            </template>
        </CommonModal>

    </div>
</template>
<script>

import sendForm from "@/modules/initialComplaintForm/pages/sendFoem.vue";

export default {
    components: {sendForm},
    data() {
        return {
            rows: 10,  // Toplam satır sayısı
            perPage: 1,// Sayfa başına satır sayısı
            currentPage: 1,
            masks: {
                input: "DD-MM-YYYY",
            },
            users: null,
            formName:null,
            showModal:false
        }
    },
    metaInfo() {
        return {
            title: this.$t("initial_complaint_form"),
        };
    },
    watch:{
      currentPage(){
          this.openHtmlModal()
      }
    },

    mounted() {
       this.openHtmlModal();

    },

    methods: {
        openHtmlModal(){
            this.formName='send-form'
            this.$refs.sendForm.$refs.commonModal.show();
        },
        successForm(){
            this.formName=null
            this.$refs.sendForm.$refs.commonModal.hide();
        }
    }
}
</script>
<style>
.b-pagination .page-item:first-child,
.b-pagination .page-item:last-child {
    display: none;
}


</style>

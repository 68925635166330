<template>
    <div>
        <!-- Logo -->
        <div style="width: 100%; display: flex; justify-content: end; margin: 0 auto;">
            <img src="../../../assets/img/BAU_Logo.png" height="55" ref="logoImage"/>
        </div>

        <!-- Header -->
        <div>
            <h6>INITIAL COMPLAINT FORM</h6>
        </div>

        <div ref="printPdf">
            <table class="form-table">
                <tr>
                    <td style="padding-bottom: 30px; width: 50%; vertical-align: top;"><strong>{{ $t('complainant_last_name') }}:</strong></td>
                    <td style="width: 30%; vertical-align: top;"><strong>{{ $t('complainant_first_name') }}:</strong></td>
                    <td style="width: 20%; vertical-align: top; border-right: none;"><strong>{{ $t('telephone') }}:</strong></td>
                </tr>

                <tr>
                   <td style="padding-bottom: 30px; width: 40%; vertical-align: top;"><strong>{{ $t('address_no_and_street') }}:</strong></td>
                   <td style="width: 30%; vertical-align: top;"><strong>{{ $t('city') }}:</strong></td>
                   <td style="min-width: 60px; max-width: 60px; vertical-align: top;"><strong>{{ $t('State') }}:</strong></td> <!-- Sabit genişlik -->
                   <td style="min-width: 100px; max-width: 100px; vertical-align: top;"><strong>{{ $t('zip') }}:</strong></td> <!-- Sabit genişlik -->
                </tr>

                <tr>
                    <td style="padding-bottom: 30px; vertical-align: top;"><strong>{{ $t('todays_date') }}:</strong></td>
                    <td colspan="3" style="vertical-align: top;"><strong>{{ $t('dates_of_alleged_tncidents') }}:</strong></td>
                </tr>

                <tr>
                    <td colspan="4" style="padding-bottom: 30px; vertical-align: top;"><strong>{{ $t('accused_person_initial_complaint_form') }}:</strong></td>
                </tr>

                <tr>
                    <td colspan="4" style="padding-bottom: 35px;"><strong>{{ $t('list_any_witness_names') }}:</strong></td>
                </tr>

                <tr>
                    <td colspan="4" style="vertical-align: top; padding-bottom: 60px;"><strong>{{ $t('list_where_the_incidents_occurred') }}:</strong></td>
                </tr>

                <tr>
                    <td colspan="4" style="vertical-align: top;  padding-bottom: 400px;"><strong>{{ $t('incident_details_initial_complaint_form') }}</strong></td>
                </tr>

                <tr>
                    <td colspan="4" style="text-align: center; vertical-align: top; background-color: #E9EAEC;"><strong>{{ $t('signatures').toUpperCase() }}</strong></td>
                </tr>

                <tr>
                    <td colspan="4" style="vertical-align: top;">{{ $t('complaint_verification_initial_complaint_form') }}.</td>
                </tr>

                <tr>
                    <td style="padding-bottom: 30px; vertical-align: top;"><strong>{{ $t('complainant_signature') }}:</strong></td>
                    <td colspan="3" style="vertical-align: top;"><strong>{{ $t('date_signed') }}:</strong></td>
                </tr>

                <tr>
                    <td style="padding-bottom: 30px; vertical-align: top;"><strong>{{ $t('complainant_received_by') }}:</strong></td>
                    <td colspan="3" style="vertical-align: top;"><strong>{{ $t('date_signed') }}:</strong></td>
                </tr>

            </table>
        </div>

        <!-- Submit button -->
        <div class="col-12 mt-5">
            <div class="mb-4 d-flex justify-content-center">
                <h6>
                    <b-button variant="primary" class="no-button" @click="submitForm">{{ $t('submit') }}</b-button>
                </h6>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        submitForm() {

        }
    }
};
</script>

<style scoped>

/* Tablo style */
.form-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    border: 1px solid #ddd; /* Tablonun dış çerçevesi */
}

/* Tablodaki hücrelerde metni sol üst köşeye hizala ve padding ver */
.form-table td {
   /* padding-bottom: 30px;  Genel boşluk ekle */
    vertical-align: top; /* Yazıları sol üstten başlat */
    border: 1px solid #ddd; /* Hücrelerin kenarlıkları */
}

.form-table td[style*="border-right: none;"] {
    border-right: none;
}

/* Button style */
b-button {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    cursor: pointer;
}

b-button:hover {
    background-color: #0056b3;
}
</style>
